const moment = require('moment-timezone')

export const getTimeWithTimezoneOffset = (time, offset) => {
  if (!time || !moment(time).isValid()) {
    return moment() // If no valid time is provided, return current time
  }

  const offsetNumber = Number(offset)

  // If no offset or offset is 0, fallback to user's current offset
  if (Number.isNaN(offsetNumber) || offsetNumber === 0) {
    const currentOffset = moment().utcOffset()

    // If the user's timezone offset is 0, return time with that offset
    if (currentOffset === 0) {
      return moment(time).utcOffset(currentOffset)
    }

    return moment(time) // Use local time if no specific offset
  }

  // Apply the specific offset
  return moment(time).utcOffset(offsetNumber)
}
