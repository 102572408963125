/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueFilterDateFormat from 'vue-filter-date-format'
import App from './App.vue'
import store from './store'
import localStore from './localStore'
import router from './router'
import { axiosAdminInstance, axiosInstance, axiosParticipantInstance, axiosSiteInstance } from './utils/axios'
import Moment from 'moment-timezone'
import { extendMoment } from 'moment-range'
import { Webrtc } from './utils/webrtc'
import i18n from './utils/i18n'
import { Utils } from './utils/utils'
import './theme/default.scss'
import DetectRTC from 'detectrtc'
import * as EBML from 'ts-ebml'
import jQuery from './utils/jquery'
import 'bootstrap'
import datePicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import 'select2'
import 'select2/dist/css/select2.css'
import FileSelector from './components/library/FileSelector'
import NoSleep from './utils/nosleep'
import VueHotkey from 'v-hotkey'
import VuePassword from 'vue-password'

import './utils/logger'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import {
  faCircleExclamation,
  faVolume,
  faInfoCircle,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faComment,
  faCommentLines,
  faPenRuler,
  faTrashCan,
  faLineHeight,
  faEraser,
  faHorizontalRule,
  faRotateLeft,
  faHandPointer,
  faCircle as faCircleLight,
  faSquare,
  faText,
  faHighlighter,
  faPencil,
  faBars,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faEnvelopesBulk,
  faCopy,
  faEllipsisVertical,
  faClipboardListCheck,
  faUserHeadset,
  faCamera,
  faVideo,
  faVideoSlash,
  faMicrophone,
  faMicrophoneSlash,
  faSlash,
  faSpeaker,
  faComments,
  faSpinner,
  faRightFromBracket,
  faUserClock,
  faImagePolaroid,
  faAnglesUp,
  faAnglesDown,
  faAnglesLeft,
  faAnglesRight,
  faPaperPlane,
  faPaperclip,
  faQuestionSquare,
  faHeartCrack,
  faWebcam,
  faWebcamSlash,
  faXmark,
  faCheck,
  faUserLarge,
  faUserLargeSlash,
  faClock,
  faCalendar,
  faCalendarXmark,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faTrash,
  faLink,
  faPlus,
  faChartUser,
  faFilePdf,
  faTv,
  faAngleDown,
  faAngleUp,
  faSparkles,
  faLanguage,
  faPassport,
  faPlay,
  faPause,
  faBell,
  faBellOn,
  faTriangleExclamation,
  faFileAudio,
  faWaveformLines,
  faFileSignature
} from '@fortawesome/pro-light-svg-icons'
import {
  faPhoneHangup,
  faImage,
  faWifiSlash,
  faEnvelopeOpen,
  faTrash as faTrashSolid
} from '@fortawesome/pro-solid-svg-icons'
import { faTriangleExclamation as faTriangleExclamationSolid, faPhone, faCircle, faArrowRight, faVideo as faVideoSolid, faCirclePlay, faGear, faHand } from '@fortawesome/free-solid-svg-icons'

import Header from './components/Header'
import Footer from './components/Footer'
import FooterParticipant from './components/FooterParticipant'
import CallAgent from './components/modals/CallAgent'
import FeedbackForm from './components/modals/FeedbackForm'
import ColorPicker from './components/library/ColorPicker'
import './registerServiceWorker'
import { simd } from 'wasm-feature-detect'
import { datadogRum } from '@datadog/browser-rum'
import DownloadScriptixTranscriptionModal from './components/modals/ScriptixTranscription'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'

import { version } from '../package.json'
window.version = version
document.querySelector('html').setAttribute('version', version)

if (process.env.VUE_APP_DD_ENABLED === 'true' && process.env.VUE_APP_DD_ENV && ['acceptance', 'sandbox', 'production'].includes(process.env.VUE_APP_DD_ENV)) {
  datadogRum.init({
    applicationId: '07d422cd-f8a6-4df2-82f2-da1f1a453ff7',
    clientToken: 'puba42879dec0e25d2615fbec5e7f3a2e1b',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.eu',
    service: 'weseedo-app',
    env: process.env.VUE_APP_DD_ENV,
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  })
}

// Fix old style URLs with hashtag
if (window.location.hash !== '') {
  let url = ''
  if (window.location.hash.startsWith('#/login_conversation/')) {
    url = window.location.hash.replace('#/login_conversation/', '/meeting/')
  } else {
    url = window.location.hash.replace('#/', '')
  }
  window.location = url
}

const moment = extendMoment(Moment)
const noSleep = new NoSleep()

moment.updateLocale('en', {
  week: { dow: 1 } // Monday is the first day of the week
})

window.DetectRTC = DetectRTC
window.EBML = EBML

library.add(faTriangleExclamation, faTriangleExclamationSolid, faCircleExclamation, faBell, faBellOn, faPlay, faVolume, faInfoCircle, faArrowUp, faArrowDown, faArrowLeft, faComment, faCommentLines, faPenRuler, faTrashCan, faLineHeight, faEraser, faHorizontalRule, faRotateLeft, faHandPointer, faCircleLight, faSquare, faText, faHighlighter, faPencil, faBars, faMagnifyingGlassMinus, faMagnifyingGlassPlus, faEnvelopesBulk, faCopy, faEllipsisVertical, faClipboardListCheck, faPause, faUserHeadset, faCamera, faVideo, faVideoSolid, faVideoSlash, faMicrophone, faMicrophoneSlash, faSlash, faSpeaker, faComments, faSpinner, faRightFromBracket, faUserClock, faImagePolaroid, faAnglesUp, faAnglesDown, faAnglesLeft, faAnglesRight, faPaperPlane, faPaperclip, faQuestionSquare, faHeartCrack, faWebcam, faWebcamSlash, faXmark, faCheck, faUserLarge, faUserLargeSlash, faClock, faCalendar, faCalendarXmark, faChevronUp, faChevronDown, faChevronLeft, faChevronRight, faTrash, faLink, faPhone, faPhoneHangup, faCircle, faArrowRight, faPlus, faChartUser, faFilePdf, faTv, faAngleDown, faAngleUp, faSparkles, faLanguage, faPassport, faCirclePlay, faGear, faHand, faWifiSlash, faImage, faFileAudio, faWaveformLines, faFileSignature, faEnvelopeOpen, faTrashSolid)
dom.watch()

Vue.component('file-selector', FileSelector)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('weseedo-header', Header)
Vue.component('weseedo-footer', Footer)
Vue.component('weseedo-footer-participant', FooterParticipant)
Vue.component('date-picker', datePicker)
Vue.component('color-picker', ColorPicker)
Vue.component('modal-call-agent', CallAgent)
Vue.component('modal-feedback-form', FeedbackForm)
Vue.component('vue-password', VuePassword)
Vue.component('download-scriptix-transcription', DownloadScriptixTranscriptionModal)

Vue.config.productionTip = false

Object.defineProperty(Vue.prototype, '$env', { value: process.env })
Object.defineProperty(Vue.prototype, '$axios', { value: axiosInstance })
Object.defineProperty(Vue.prototype, '$axiosSite', { value: axiosSiteInstance })
Object.defineProperty(Vue.prototype, '$axiosParticipant', { value: axiosParticipantInstance })
Object.defineProperty(Vue.prototype, '$webrtc', { value: new Webrtc() })
Object.defineProperty(Vue.prototype, '$eventBus', { value: new Vue() })
Object.defineProperty(Vue.prototype, '$', { value: jQuery })
Object.defineProperty(Vue.prototype, 'moment', { value: moment })
Object.defineProperty(Vue.prototype, '$localStore', { value: localStore })
Object.defineProperty(Vue.prototype, '$noSleep', { value: noSleep })
Object.defineProperty(Vue.prototype, '$utils', { value: new Utils() })

document.addEventListener('click', function enableNoSleep () {
  if (!noSleep.isEnabled() && store.getters.getUser !== false && store.getters.getUser.entity && (store.getters.getUser.entity.id === '60110ee912c04b14f0f5c3e9' || store.getters.getUser.entity.id === '53abbd02e138231d7d1d8810')) {
    noSleep.enable()
  }
}, false)

const nlLocale = {
  dayNames: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
  dayNamesShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
  dayNamesMin: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
  monthNames: [
    'januari', 'februari', 'maart', 'april', 'mei', 'juni',
    'juli', 'augustus', 'september', 'oktober', 'november', 'december'
  ],
  monthNamesShort: [
    'jan', 'feb', 'mrt', 'apr', 'mei', 'jun',
    'jul', 'aug', 'sep', 'okt', 'nov', 'dec'
  ],
  weekHeader: 'Wk',
  firstDayOfWeek: 1, // Monday as the first day of the week (Dutch convention)
  dateFormat: 'dd-mm-yy' // Dutch commonly uses DD-MM-YYYY format
}

const enLocale = {
  dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  dayNamesMin: ['Sun', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sat'],
  monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  weekHeader: 'Wk',
  firstDayOfWeek: 1,
  dateFormat: 'mm/dd/yy'
}

Vue.use(VueFilterDateFormat)
Vue.use(FileSelector)
Vue.use(VueHotkey)
Vue.use(PrimeVue, {
})
Vue.use(ToastService)

Vue.use(ToastService)

Vue.directive('select2', {
  inserted (el) {
    jQuery(el).on('select2:select', () => {
      const event = new Event('change', { bubbles: true, cancelable: true })
      el.dispatchEvent(event)
    })

    jQuery(el).on('select2:unselect', () => {
      const event = new Event('change', { bubbles: true, cancelable: true })
      el.dispatchEvent(event)
    })
  }
})

Vue.directive('tooltip', {
  bind: function (el, binding) {
    if (typeof binding.value !== 'undefined') {
      jQuery(el).tooltip({
        title: binding.value,
        placement: binding.arg,
        trigger: 'hover'
      })
    }
  },
  update: function (el, binding) {
    jQuery(el).attr('data-original-title', binding.value)
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  data () {
    return {
      bell: null,
      emitStatus: true,
      emitDone: true,
      emitTimeout: null,
      isMobileDevice: window.DetectRTC.isMobileDevice,
      isIpad: navigator.userAgent.includes('Mac') && typeof navigator.maxTouchPoints !== 'undefined' && navigator.maxTouchPoints > 2,
      webrtcJoin: null,
      isAppReconnecting: false,
      isAppDisconnected: false,
      isBeta: typeof process.env.VUE_APP_IS_BETA !== 'undefined' && process.env.VUE_APP_IS_BETA === 'true',
      nodeEnv: process.env.NODE_ENV,
      isWebsocketConnected: false,
      isWebsocketAuthenticated: false,
      segmentationModel: null,
      browserSIMDSupport: null
    }
  },
  methods: {
    setFullName (user) {
      if (user !== false) {
        if (typeof user.first_name !== 'undefined' && typeof user.last_name !== 'undefined') {
          return (user.first_name + ' ' + user.last_name).trim()
        } else if (typeof user.first_name !== 'undefined' && typeof user.last_name === 'undefined') {
          return user.first_name
        } else if (typeof user.first_name === 'undefined' && typeof user.last_name !== 'undefined') {
          return user.last_name
        } else {
          return 'Agent'
        }
      } else {
        return 'Agent'
      }
    },
    setFirstName (user) {
      if (user !== false && typeof user.first_name !== 'undefined') {
        return user.first_name
      } else {
        return 'Agent'
      }
    },
    async getNotificationBar () {
      const notifications = await axiosAdminInstance.getNotifications()

      if (typeof notifications.status !== 'undefined' && notifications.status === 200 && typeof notifications.data !== 'undefined') {
        this.$store.commit('setNotificationBar', notifications.data)
      }
    }
  },
  computed: {
    getFeedbackForm () {
      const feedbackForm = this.$store.getters.getFeedbackForms.filter((form) => {
        if (this.$store.getters.getUser !== false) {
          return form.type === 'meetingFeedbackAgent'
        } else {
          return form.type === 'meetingFeedbackParticipant'
        }
      })

      console.log('Get feedback form from store:', feedbackForm.length === 0 ? null : feedbackForm[0].id)

      return feedbackForm.length === 0 ? null : feedbackForm[0]
    }
  },
  async mounted () {
    console.log('Mounted app')
    simd().then(async (simdSupported) => {
      this.browserSIMDSupport = simdSupported
    })

    this.$store.commit('setLocale')
    const lang = document.querySelector('html').getAttribute('lang')?.toLowerCase()
    this.$primevue.config.locale = lang === 'nl' ? nlLocale : enLocale // set the local for primevue calendar
    // Stop all code below in mounted for button and form
    if (window.location.href.indexOf('/website_button') !== -1 || window.location.href.indexOf('/plannen') !== -1 || window.location.href.indexOf('/schedule') !== -1) {
      return true
    }

    this.bell = new Audio('/sounds/bell.mp3')
    this.bell.loop = false

    // TODO: remove setProduct here based on env when its rebuild to features, for related todos see axios.js
    store.commit('setProduct', process.env.VUE_APP_PRODUCT)
    store.commit('setNotificationBar', {})
    store.commit('removeFeedbackForms')
    store.commit('setMeetingStarted', false)
    store.commit('setMeetingEnded', false)
    store.commit('setMeetingDisconnected', false)
    store.commit('setForcedLogout', false)

    this.$webrtc.stopLocalStreams()

    if (window.location.href.indexOf('/meeting') === -1 || this.$store.getters.getProduct === 'personal') {
      this.$axios.meetingLogout()
    }

    if (this.$store.getters.getUser === false || this.$store.getters.isUINavigationEnabled === false) {
      console.log('User is not found or UI is disabled, logout and disconnect WebRTC anyway')
      this.$axios.logout(false)
      this.$webrtc.disconnect(false)
    }

    if (this.$store.getters.getUser !== false) {
      if (window.location.href.indexOf('/meeting/') === -1) {
        const me = await this.$axios.getUser(true)

        if (me.status === 200) {
          this.$webrtc.connect()
        }
      }
    }

    this.$webrtc.EventBus.$on('onConnected', (event) => {
      this.isWebsocketConnected = true
    })

    this.$webrtc.EventBus.$on('onAuthenticated', (event) => {
      this.isWebsocketAuthenticated = true
    })

    this.$webrtc.EventBus.$on('onDeviceList', (event) => {
      console.log('Listing devices in main.js')

      this.$store.commit('setDeviceList', event.devices)
    })

    this.$webrtc.EventBus.$on('onRequestJoin', async (event) => {
      console.log('onRequestJoin event triggered')
      if (this.$store.getters.getProduct === 'personal') {
        if (this.$store.getters.getUser !== false) {
          let isMeetingHost = true
          if (event.action === 'invite' || event.action === 'colleague') {
            isMeetingHost = false
          }

          this.$store.commit('setIsMeetingHost', isMeetingHost)

          this.$eventBus.$emit('callAgent', event.action, event.requestedBy, event.room_id, event.account)
        }
      } else {
        if (this.$store.getters.getMeeting === false) {
          let betaQuery = ''
          if (this.isBeta) {
            betaQuery = '&beta=true'
          }

          const meetings = await this.$axios.get(this.$axios.routes.meeting + '?room_id=' + event.room_id + betaQuery)

          if (typeof meetings.data.result === 'undefined' || meetings.data.result === null) {
            return false
          }
          const meeting = meetings.data.result[0]

          this.$store.commit('setMeeting', meeting)
        }

        if (this.$route.name !== 'meeting') {
          this.webrtcJoin = {
            room_id: event.room_id,
            product: this.$store.getters.getMeeting.product,
            stream2way: this.$store.getters.getMeeting.stream_camera_two_way
          }

          await this.$router.push({ name: 'meeting' })
        } else {
          this.$webrtc.join(event.room_id, this.$store.getters.getMeeting.product, this.$store.getters.getMeeting.stream_camera_two_way, this.$store.getters.getUser !== false)
        }
      }
    })

    this.$webrtc.EventBus.$on('onEndCall', (event) => {
      console.log('onEndCall event triggered')
      if (this.$store.getters.userHasFeature('weseedo_direct_recording') && this.$store.getters.getUser !== false) {
        // COULD-DO: add this to the config as well so that other user who need recording
        // for direct but do not want to upload to a remote server can do so
        this.$webrtc.setUploadBumicomRecording(true, this.$axios)
      }
      if (this.$store.getters.getMeetingEnded === false) {
        console.log('GET MEETING ENDED IS FALSE')
        this.$store.commit('setMeetingStarted', false)
        this.$store.commit('setMeetingEnded', true)

        if (this.$store.getters.getUser !== false) {
          this.$store.commit('setMeetingEndedAs', 'agent')
        } else {
          this.$store.commit('setMeetingEndedAs', 'participant')
        }

        if (this.$store.getters.getMeeting !== false) {
          console.log('GET MEETING IS NOT EMPTY')
          this.$utils.postMessageToParent('MEETING_ENDED', { meeting_id: this.$store.getters.getMeeting.id })

          let lastMeetingInfo = {
            id: this.$store.getters.getMeeting.id,
            is_appointment: this.$store.getters.getMeeting.is_appointment
          }

          if (this.$store.getters.getUser !== false) {
            const participant = this.$store.getters.getMeeting.participants.filter(participant => typeof participant.user !== 'undefined' && participant.user === this.$store.getters.getUser.id)

            lastMeetingInfo.participant = participant[0].id
            lastMeetingInfo.participantName = participant[0].name
            lastMeetingInfo.user = this.$store.getters.getUser.id
          } else {
            lastMeetingInfo.participant = this.$store.getters.getMeeting.participant.id
            lastMeetingInfo.participantName = this.$store.getters.getMeeting.participant.name
          }

          this.$store.commit('setLastMeetingInfo', lastMeetingInfo)

          this.$axios.meetingLogout()
          this.$webrtc.stopLocalStreams()

          let newStatus
          if (this.$store.getters.getStatus === 'online') {
            newStatus = 'online'
          } else {
            newStatus = 'offline'
          }

          if (this.$store.getters.getUser === false || this.$store.getters.isUINavigationEnabled === false) {
            console.log('User is not found or UI is disabled, disconnect WebRTC anyway')
            this.$webrtc.disconnect(false)
          } else {
            console.log('RESET THE STATUS TO', newStatus)
            setTimeout(() => {
              this.$webrtc.setStatus(this.$store.getters.isUINavigationEnabled ? newStatus : 'offline', true, false, false)
            }, 1000)
          }
        }
      }
    })

    this.$webrtc.EventBus.$on('onLostConnection', () => {
      console.log('onLostConnection event triggered')
      this.isAppReconnecting = true
    })

    /*
    this.$webrtc.EventBus.$on('onReconnectAttempt', (attempt) => {
      console.log('onReconnectAttempt event triggered')
      this.isAppReconnecting = true
      this.reconnectAttempt = attempt.attempt
    })
     */

    this.$webrtc.EventBus.$on('onReconnected', () => {
      console.log('onReconnected event triggered')
      this.isAppReconnecting = false
    })

    this.$webrtc.EventBus.$on('onDisconnected', (event) => {
      console.log('onDisconnected event triggered')

      this.isAppReconnecting = false

      if (['logout', 'forced_logout'].includes(this.$route.name) === false && this.$store.getters.getMeetingEnded === false && this.$store.getters.getMeetingDisconnected === false && this.$store.getters.getForcedLogout === false) {
        if (this.$store.getters.getUser !== false) {
          this.$store.commit('setMeetingDisconnectedAs', 'agent')
        } else {
          this.$store.commit('setMeetingDisconnectedAs', 'participant')
        }
        this.$store.commit('setMeetingDisconnected', true)

        if (this.$store.getters.getMeeting !== false) {
          this.$axios.meetingLogout()
        }

        if (this.$store.getters.getUser !== false) {
          this.$axios.logout(false)
        }

        this.$webrtc.stopLocalStreams()

        console.log('Set isAppDisconnected')
        this.isAppDisconnected = true
      }
    })

    this.$webrtc.EventBus.$on('onForcedLogout', (event) => {
      if (this.$store.getters.getForcedLogout === false) {
        this.$store.commit('setForcedLogout', true)

        this.$router.push({ name: 'forced_logout' })
      }
    })

    this.$webrtc.EventBus.$on('onVisitorOnline', (username, action, agentId) => {
      if (this.$store.getters.getUser !== false) {
        if (this.$store.getters.userHasFeature('waitingroom') && action === 'join-room' && agentId === this.$store.getters.getUser.id && this.$store.getters.getMeeting === false && (typeof this.$route.query.disable_ringtone_waiting_room === 'undefined' || this.$route.query.disable_ringtone_waiting_room === 'false')) {
          this.bell.play()
        }
      }
    })

    this.$(window).on('resize', () => {
      setTimeout(() => {
        if (typeof window.innerWidth !== 'undefined' && typeof window.innerHeight !== 'undefined') {
          if (window.innerWidth > window.innerHeight) {
            this.$eventBus.$emit('changeWindowOrientation', 'landscape')
          } else if (window.innerWidth < window.innerHeight) {
            this.$eventBus.$emit('changeWindowOrientation', 'portrait')
          } else {
            // when pixels are the same wait for one more time on resize
            this.$(window).one('resize', () => {
              if (window.innerWidth > window.innerHeight) {
                this.$eventBus.$emit('changeWindowOrientation', 'landscape')
              } else if (window.innerWidth < window.innerHeight) {
                this.$eventBus.$emit('changeWindowOrientation', 'portrait')
              }
            })
          }
        } else {
          // No inner Width and Height found, but orientation still changed. Just commit with orientation null
          this.$eventBus.$emit('changeWindowOrientation', null)
        }
      }, 250)
    })

    /*
    if (this.isMobileDevice) {
      this.$(window).on('resize', () => {
        if (typeof this.$route.name !== 'undefined' && this.$route.name !== 'meeting') {
          const footer = this.$('#footerContainer').outerHeight()
          if (this.$('body').find('.h-100-generated').length === 0) {
            this.$('body').find('.h-100').first().addClass('h-100-generated').removeClass('h-100')
          }
          this.$('body').find('.h-100-generated').css({ 'height': window.innerHeight - footer + 'px' })
        }
      })
    }
     */

    setInterval(() => {
      if (window.location.href.indexOf('/meeting/') === -1) {
        this.getNotificationBar()
      }
    }, 30000)
  }
}).$mount('#app')
